<template>
  <div id="map"></div>
  <div id="content">
    <div class="header">
      <h1>{{ title }}</h1>
      <p>Muni has <a href="https://twitter.com/sfmta_muni/status/1494446768297164801" target="_blank">new battery-electric buses</a> with a special white-and-red color scheme. Track them on this map!</p>
    </div>
    <div class="main-content">
      <p class="explain">Muni has <a href="https://twitter.com/sfmta_muni/status/1494446768297164801" target="_blank">new battery-electric buses</a> with a special white-and-red color scheme. Track them on this map!</p>
      <p class="num-trains" v-if="initialLoad">There {{ mapVehicles.length == 1 ? 'is' : 'are' }} <strong>{{ mapVehicles.length == 0 ? 'no' : mapVehicles.length }} e-bus{{ mapVehicles.length == 1 ? '' : 'es' }}</strong> out right now{{ mapVehicles.length == 0 ? '.' : ':' }}</p>
      <ul class="vehicle-list" v-if="initialLoad">
        <li v-for="vehicle in predictableVehicles">A {{ vehicle.routeTag }} line bus heading {{ vehicle.directionName }}</li>
        <li v-if="unpredictableVehicles.length > 0">{{ unpredictableVehicles.length }} bus{{ unpredictableVehicles.length == 1 ? '' : 'es' }} not in motion</li>
      </ul>
    </div>
  </div>
  <div class="nav">{{ title }}</div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'

export default {
  name: 'App',
  data: function(){ return {
      vehicleIds: [5000, 5001, 5002, 5003, 5004, 5005, 5006, 5007],
      loading: false,
      initialLoad: false,
      vehicles: [],
      map: null,
      routeConfigs: {},
      title: 'Track new Muni e-buses',
      originBounds: [-122.525172,37.703432,-122.349734,37.807123],
      markers: []
    }
  },
  mounted: function(){
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2FydmluIiwiYSI6IjdGSUItY1kifQ.dhkr11vInR8pEWrMYMY4gA';
    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/dark-v10', // style URL
      bounds: this.originBounds
    });

    this.map.on('load',() => {
      this.map.fitBounds(this.originBounds, {
        padding: {
          top: 24,
          left: 24,
          right: 24,
          bottom: window.innerWidth <= 800 ? 170 : 24
        }
      })

      // this.map.loadImage(
      //   './lrv-icon.png',
      //   (error, image) => {
      //     if (error) throw error;

      //     // Add the image to the map style.
      //     this.map.addImage('lrv', image);

          // Add a data source containing one point feature.
          // this.map.addSource('vehicles', {
          //   'type': 'geojson',
          //   'data': {
          //     'type': 'FeatureCollection',
          //     'features': []
          //   }
          // });

         this.map.addSource('routes', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': []
            }
          });

          this.map.addLayer({
            'id': 'routes',
            'type': 'line',
            'source': 'routes',
            'layout': {
            'line-join': 'round',
            'line-cap': 'round'
            },
            'paint': {
            'line-color': '#FCD581',
            'line-width': 3
            }
          });

          // Add a layer to use the image to represent the data.
          // this.map.addLayer({
          //     'id': 'vehicles',
          //     'type': 'symbol',
          //     'source': 'vehicles', // reference the data source
          //     'layout': {
          //       'icon-image': 'lrv', // reference the image
          //       'icon-size': 0.3
          //     }
          //   });

          this.map.addControl(
            new mapboxgl.GeolocateControl({
              positionOptions: {
              enableHighAccuracy: true
              },
              // When active the map will receive updates to the device's location as it changes.
              trackUserLocation: true,
              showUserHeading: false
            })
          );

          this.loadPositions(10000);

      // });
    })
  },
  computed: {
    predictableVehicles: function(){
      return this.vehicles.filter((vehicle) => {
        return vehicle.predictable
      })
    },
    unpredictableVehicles: function(){
      return this.vehicles.filter((vehicle) => {
        if(vehicle.predictable){ return false }

        var closest = 1000000;
        this.predictableVehicles.forEach((p) => {
          var pointA = turf.point(p.coordinate);
          var pointB = turf.point(vehicle.coordinate);

          var distance = turf.distance(pointA,pointB);
          if(distance < closest){
            closest = turf.distance(pointA,pointB);
          }
        })

        return closest > 1200;
      })
    },
    mapVehicles: function(){
      return this.predictableVehicles.concat(this.unpredictableVehicles)
    }
  },
  methods: {
    loadPositions: function(interval){
      if(this.loading){ return }

      this.loading = true;

      var results = {};

      this.vehicleIds.forEach((vehicleId) => {
        this.loadPositionForVehicle(vehicleId).then((result) => {
          results[vehicleId] = result;

          if(Object.keys(results).length == this.vehicleIds.length){
            this.loading = false;
            this.initialLoad = true;

            var vehicles = [];
            this.vehicleIds.forEach((vehicleId) => {
              if(results[vehicleId] && results[vehicleId].vehicle){
                var thisVehicle = results[vehicleId].vehicle;
                thisVehicle.predictable =  (results[vehicleId].vehicle.predictable == "true")
                thisVehicle.coordinate = [parseFloat(thisVehicle.lon),parseFloat(thisVehicle.lat)]
                thisVehicle.directionName = thisVehicle.dirTag ? (thisVehicle.dirTag.indexOf('_I_') > -1 ? "Inbound" : "Outbound") : null
                vehicles.push(thisVehicle);
              }
            })

            this.updateVehicles(vehicles).then(() => {
              if(interval){
                setTimeout(() => {
                  this.loadPositions(interval)
                }, interval);
              }
            })


          }
        })
      })
    },
    loadPositionForVehicle: function(vehicleId){
      return fetch('https://retro.umoiq.com/service/publicJSONFeed?command=vehicleLocation&a=sf-muni&v=' + vehicleId).then((result) => {
        return result.json();
      }).catch(() => {
        return {};
      })
    },
    updateVehicles: function(vehicles){
      return new Promise((resolve,reject) => {
        var neededRouteConfigs = [];

        var features = vehicles.map((vehicle) => {
          if(vehicle.routeTag && !this.routeConfigs[vehicle.routeTag] && neededRouteConfigs.indexOf(vehicle.routeTag) < 0){
            neededRouteConfigs.push(vehicle.routeTag);
          }

          return {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': vehicle.coordinate
                  }
                }
        });

        this.fetchRouteConfigs(neededRouteConfigs).then(() => {
          var addedConfigs = {};
          var routeLines = [];
          vehicles.forEach((vehicle) => {
            if(addedConfigs[vehicle.routeTag] || !this.routeConfigs[vehicle.routeTag]){ return }

            addedConfigs[vehicle.routeTag] = true;
            this.routeConfigs[vehicle.routeTag].path.forEach((line) => {
              routeLines.push({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: line.point.map((point) => {
                    return [parseFloat(point.lon),parseFloat(point.lat)]
                  })
                }
              })
            })
          })

          this.map.getSource("routes").setData({
            type: "FeatureCollection",
            features: routeLines
          })

          // this.map.getSource("vehicles").setData({
          //   type: "FeatureCollection",
          //   features: features
          // })

          this.vehicles = vehicles;

          this.updateMarkers(vehicles);

          resolve();
        })
      });
    },
    fetchRouteConfigs: function(tags){
      return new Promise((resolve,reject) => {
        if(!tags || tags.length == 0){ resolve(); return; }

        var done = 0;

        tags.forEach((tag) => {
          this.fetchRouteConfig(tag).then((result) => {
            if(result.route){
              this.routeConfigs[tag] = result.route;
            }
            done++;

            if(done == tags.length){
              resolve();
            }
          })
        })
      })
    },
    fetchRouteConfig: function(routeTag){
      return fetch('https://retro.umoiq.com/service/publicJSONFeed?command=routeConfig&a=sf-muni&r=' + routeTag).then((result) => {
        return result.json();
      }).catch(() => {
        return {};
      });
    },
    updateMarkers: function(){
      var max = Math.max(this.mapVehicles.length, this.mapVehicles.length);
      for(var i = max - 1; i >= 0; i--){
        if(this.mapVehicles[i]){
          if(!this.markers[i] || this.markers[i] == null){
            this.markers[i] = new mapboxgl.Marker(document.createElement('div'))
              .setLngLat([0,0])
              .addTo(this.map);
          }
          this.markers[i].setLngLat(this.mapVehicles[i].coordinate);
          var markerHTML = `<div class="lrv-icon-container" style="width: 100%; text-align: center">
                              <img src="/lrv-icon.png" class="lrv-icon" />
                            </div>`
                          if(this.mapVehicles[i].predictable){
                            markerHTML += `<div class="lrv-info">
                              <img src="/arrow.svg" class="arrow-img" style="transform: translate3d(-0.5px,1.5px,0) rotate(` + (this.mapVehicles[i].heading ? this.mapVehicles[i].heading : 0) + `deg)" />&nbsp;` + this.mapVehicles[i].routeTag + ` line
                            </div>`
                          }
          this.markers[i].getElement().innerHTML = markerHTML
        } else if (this.markers[i] && this.markers[i] != null){
          this.markers[i].remove();
          delete this.markers[i];
        }
      }
    }
  }
}

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}
</script>

<style>
@font-face {
    font-family: 'grapheinpro';
    src: url('/font/grapheinpro-book-webfont.woff2') format('woff2'),
         url('/font/grapheinpro-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'grapheinpro';
    src: url('/font/grapheinpro-bold-webfont.woff2') format('woff2'),
         url('/font/grapheinpro-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: 700;
}

@font-face {
    font-family: 'grapheinpro';
    src: url('/font/grapheinpro-black-webfont.woff2') format('woff2'),
         url('/font/grapheinpro-black-webfont.woff') format('woff');
    font-weight: 800;
    font-style: 800;
}

body, html, app, #map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-weight: 800;
  font-size: 18px;
  line-height: 50px;
  padding-left: 16px;
  display: none;
}

.header, .nav {
  background: #ba0c2f;
  color: white;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: 'grapheinpro', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
  font-weight: normal;
}

#content {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 33%;
  bottom: auto;
  height: auto;
  background: #2E282A;
  color: white;
  text-align: left;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 6px 20px rgba(0,0,0,1);
}

.explain {
  display: none;
}

.header {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
}

h1 {
  font-weight: 800;
  font-size: 26px;
}

.main-content {
  padding: 0 16px;
}

@media(max-width: 780px){
  .nav {
    display: block;
  }

  .header {
    display: none;
  }

  #content {
    top: auto;
    bottom: 40px;
    width: auto;
    left: 12px;
    right: 12px;
    height: auto;
  }

  .explain { display: block; }
}

a {
  color: white;
  text-decoration: underline;
  font-weight: 700;
}

p {
  line-height: 150%;
}

.lrv-icon {
  width: 56px;
  height: auto;
}

.lrv-info {
  background: #ba0c2f;
  color: white;
  box-shadow: 0px 2px 6px rgba(0,0,0,1);
  border-radius: 30px;
  font-family: 'grapheinpro', Avenir Next, Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin-top: -9px;
  padding: 2px 8px;
}

.arrow-img {
  width: 11px;
  height: auto;
  transform-origin: 50% 50%;
}
</style>
